import React, { useState } from 'react';
import About from '../about/About';
import '../App.css'
import MsgReader from '@kenjiuno/msgreader';
import emlformat from 'eml-format/lib/eml-format';

function Index() {
  const [uploadedEmail, setUploadedEmail] = useState(false);
  const [error, setError] = useState(false);
  const [emailDetails, setEmailDetails] = useState(null);

  function _BuildData(fileExtension, uploadedEmail) {
    if (!['msg', 'eml'].includes(fileExtension)) {
      setError('Unsupported File Type');
      return;
    }

    function unpackArgs(source, sourceKey, target, targetKey, callback) {
      for (var index = 0; index < Math.min(sourceKey.length, targetKey.length); index++) { 
        callback(source, sourceKey[index], target, targetKey[index]);
      }
    }
  
    function copyToKey(source, sourceKey, target, targetKey) {
      if (Array.isArray(sourceKey) && Array.isArray(targetKey)) {
        unpackArgs(source, sourceKey, target, targetKey, copyToKey);
      } else {
        target[targetKey] = source[sourceKey];
      }
    }

    let reader = new FileReader();
    var normalizedData = {
      headers: [],
      recipients: []
    };
    switch (fileExtension) {
      case 'eml':
        function EmlCallback(error, data) {
          if (error !== null) {
            setError(error);
          }
          copyToKey(data, 'body', normalizedData, 'body');
          Object.keys(data['headers']).forEach(function(key, index) {
            if (Array.isArray(data['headers'][key])) {
              for (var headerIndex = 0; headerIndex < data['headers'][key].length; headerIndex++) {
                normalizedData['headers'].push({
                  name: key,
                  value: data['headers'][key][headerIndex],
                  index: headerIndex
                });
              }
            } else {
              normalizedData['headers'].push({
                name: key,
                value: data['headers'][key],
                index: index++
              });
            }
          });

          if ('To' in data['headers']) {
            normalizedData['recipients'].push({
              name: data['headers']['To'],
              type: 'To',
              index: 0
            })
          }
          if ('From' in data['headers']) {
            normalizedData['recipients'].push({
              name: data['headers']['From'],
              type: 'From',
              index: 1
            })
          }
          if ('Cc' in data['headers']) {
            let ccRecips = data['headers']['Cc'].split(',');
            for (var ccRecipIndex = 0; ccRecipIndex < ccRecips.length; ccRecipIndex++) {
              normalizedData['recipients'].push({
                name: ccRecips[ccRecipIndex].trim(),
                type: 'Cc',
                index: 1
              })
            }
          }
          if ('Bcc' in data['headers']) {
            let bccRecips = data['headers']['Bcc'].split(',');
            for (var bccRecipIndex = 0; bccRecipIndex < bccRecips.length; bccRecipIndex++) {
              normalizedData['recipients'].push({
                name: bccRecips[bccRecipIndex].trim(),
                type: 'Bcc',
                index: 1
              })
            }
          }

          setEmailDetails(normalizedData);
        }

        reader.onload = (function (event) {
          var buffer = event.target.result;
          emlformat.parse(buffer, EmlCallback);
        });
        reader.readAsText(uploadedEmail);
        break;
      case 'msg':
        reader.onload = (function (event) {
          var messageReader = new MsgReader(event.target.result);
          var fileData = messageReader.getFileData();
          console.log(fileData);
          copyToKey(fileData, 'body', normalizedData, 'body');
          var recipientIndex = 0;
          for (var index = 0; index < fileData['recipients'].length; index++) {
            console.log('got one')
            normalizedData['recipients'].push({
              name: '"' + fileData['recipients'][index]['name'] + '" <' + fileData['recipients'][index]['email'] + '>',
              type: fileData['recipients'][index]['recipType'],
              index: recipientIndex++ 
            });
          }

          normalizedData['recipients'].push({
            name: '"' + fileData['senderName'] + '" <' + fileData['senderEmail'] + '>',
            type: 'From',
            index: recipientIndex
          });

          // Parse Headers
          var headerPiecesRaw = fileData['headers'].split('\r\n');
          var writeIndex = -1;
          var headers = [];
          for (var headerPieceIndex = 0; headerPieceIndex < headerPiecesRaw.length; headerPieceIndex++) {
            if ([' ', '\t', ' '].includes(headerPiecesRaw[headerPieceIndex].charAt(0))) {
              headers[writeIndex] = headers[writeIndex] + ' ' + headerPiecesRaw[headerPieceIndex].trim();
            } else {
              writeIndex += 1;
              headers.push(headerPiecesRaw[headerPieceIndex]);
            }
          }

          var headerIndex = 0;
          for (var itemIndex = 0; itemIndex < headers.length; itemIndex++) {
            let splitIndex = headers[itemIndex].indexOf(':');
            if (splitIndex === -1) {
              continue;
            }

            normalizedData['headers'].push({
              name: headers[itemIndex].substring(0, splitIndex),
              value: headers[itemIndex].substring(splitIndex + 1),
              index: headerIndex++
            });
          }
          
          setEmailDetails(normalizedData);
        });
        reader.readAsArrayBuffer(uploadedEmail);
        break;
        default:
          setError('Unknown File Type')
    } 
  }

  function uploadEmail(event) {
    event.preventDefault();
    let uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      return;
    }

    setUploadedEmail(true);
    setError(false);

    // Determine The File Type
    let fileNameLower = uploadedFile.name.toLowerCase();
    let fileExtensionPosition = fileNameLower.lastIndexOf('.');

    // Verify We Have a File Extension
    if (fileExtensionPosition === -1) {
      setError('Failed to Determine File Type: Extension Not Found');
      return;
    }

    if (fileNameLower.length <= fileExtensionPosition) {
      setError('Invalid File Name');
      return;
    }

    _BuildData(fileNameLower.substring(fileExtensionPosition + 1), uploadedFile)
  }

  function Upload() {
    return (
      <div>
        <h1>Upload Email</h1>
        <ErrorContent />
        <input type="file" onChange={uploadEmail} id="uploadEmail" accept=".msg,.eml" hidden />
        <label htmlFor="uploadEmail" id="uploadButton">Upload Email</label>
      </div>
    );
  }

  function ErrorContent() {
    if (error !== false) {
      return (
       <div>
          {error}
        </div>
      );
    }

    return (
      <div>

      </div>
    );
  }

  function MainContent() {
    if (uploadedEmail && !error) {
      if (emailDetails === null) {
        return (
          <div>
            <p>Working...</p>
          </div>
        );
      }

      return (
        <div>
          <h1>Basic Email Details</h1>
          <table>
            <tbody>
              <tr>
                <td>Type</td>
                <td>Address</td>
              </tr>
            {emailDetails['recipients'].map((recipient) => (
                <tr key={'tableDisplay-recipient-' + recipient.index}>
                  <td>{recipient.type}</td>
                  <td>{recipient.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h1>Full Headers</h1>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>Value</td>
              </tr>
              {emailDetails['headers'].map((header) => (
                <tr key={'tableDisplay-header-' + header.name + '-' + header.index}>
                  <td class='nameBox'>{header.name}</td>
                  <td>
                    <textarea value={header.value.trim()} readOnly class='valueBox' />
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h1>Body</h1>
          <textarea value={emailDetails['body']} readOnly />
        </div>
      );
    }

    return (
    <div className={'splitScreen'}>
      <div className={'pane leftAlign'}>
        <About />
      </div>
      <div className={'pane centerView'}>
        <Upload />
      </div>
    </div>
    );
  }

  return (
    <div className="App">
      <MainContent />
    </div>
  );
}

export default Index;
