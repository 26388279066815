import React from 'react';
import '../App.css'

function About() {

  
  return (
    <div>
        <h1>Email Checker</h1>
        <p>Check your email headers. That simple.</p>
    </div>
  );
}

export default About;
